import React, { useState, useRef } from "react"
import Chevron from "./chevron"

// import IconButtons from "../components/Button/IconButtons"
import classes from "./accordion.module.scss"

function Accordion(props) {
  const [setActive, setActiveState] = useState("")
  const [setHeight, setHeightState] = useState("0px")
  const [setRotate, setRotateState] = useState(classes.accordionIcon)

  const content = useRef(null)

  // useEffect(() => {
  //   if (props.isOpen) toggleAccordion()
  // }, [])

  function toggleAccordion() {
    setActiveState(setActive === "" ? classes.active : "")
    setHeightState(
      setActive === classes.active ? "0px" : `${content.current.scrollHeight}px`
    )
    setRotateState(
      setActive === classes.active
        ? classes.accordionIcon
        : classes.accordionIconRotate
    )
  }

  return (
    <div
      className={classes.accordionSection}
      key={"accordion-item--" + props.index}
    >
      <button
        className={`${classes.accordionTrigger} ${setActive}`}
        onClick={toggleAccordion}
      >
        <p className={classes.accordionTitle}>{props.title}</p>
        <Chevron className={`${setRotate}`} width={10} fill={"#777"} />
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className={classes.accordionContent}
      >
        <div className={classes.accordionText}>
          <div dangerouslySetInnerHTML={{ __html: props.content }} />
        </div>
      </div>
    </div>
  )
}

export default Accordion
